import React, { useState } from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";

import ResearchNavItem from "../components/ResearchNavItem";
import "./researchNav.css";

export default function ResearchNav(props) {
  const { setFilter, filter } = props;

  function handleClick(newValue) {
    setFilter(newValue);
  }

  const data = useStaticQuery(graphql`
    {
      allContentfulArticle {
        nodes {
          type
        }
      }
    }
  `);
  const articles = data.allContentfulArticle.nodes;

  //get all the types from the articles on this page
  const allTypes = articles.map((article) => article.type);

  //remove duplicates using Set
  const types = Array.from(new Set(allTypes));

  return (
    <div className="researchNav">
      <svg
        className="researchArrow"
        min-width="12px"
        min-height="17px"
        width="12px"
        height="17px"
        viewBox="0 0 14 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.5 0H1.5V14.5H11.2929L8.64645 11.8536L9.35355 11.1464L13.2071 15L9.35355 18.8536L8.64645 18.1464L11.2929 15.5H0.5V0Z"
          fill="black"
        />
      </svg>

      <ResearchNavItem
        filter={filter}
        title="All research"
        onClick={handleClick}
      />
      {types.map((type) => (
        <ResearchNavItem filter={filter} title={type} onClick={handleClick} />
      ))}
    </div>
  );
}
