import React, { useState } from "react";
import Layout from "../components/Layout";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

import ResearchNav from "../components/ResearchNav";
import Articles from "../components/Articles";

// markup
const IndexPage = () => {
  const [filter, setFilter] = useState("All research");

  const data = useStaticQuery(graphql`
    {
      allContentfulArticle(sort: { fields: datePublished, order: DESC }) {
        nodes {
          id
          type
          sizeOnHomepage
          title
          slug
          heroImage {
            gatsbyImageData(width: 1200)
            title
          }
        }
      }
    }
  `);

  const articles = data.allContentfulArticle.nodes;

  //get all the types from the articles on this page
  const allTypes = articles.map((article) => article.type);

  //remove duplicates using Set
  const types = Array.from(new Set(allTypes));

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>System of Systems</title>
      </Helmet>
      <ResearchNav setFilter={setFilter} filter={filter} />
      <Articles articles={articles} filter={filter} />
    </Layout>
  );
};

export default IndexPage;
