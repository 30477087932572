import React, { useState } from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";

export default function ResearchNavItem(props) {
  function handleClick() {
    props.onClick(props.title);
  }

  return (
    <button
      className={`researchButton ${
        props.title === props.filter ? "active" : ""
      }`}
      onClick={handleClick}
    >
      {props.title}
    </button>
  );
}
