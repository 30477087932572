import React, { useState } from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function Articles(props) {
  const { articles, filter } = props;
  const baseArticles = props.articles.map(function (article) {
    return (
      <div className={`gridItem ${article.sizeOnHomepage}`}>
        <Link
          className={`articleCard  ${article.type}`}
          to={`/research/${article.slug}`}
        >
          <div className="articleCardLabelWrapper">
            <p className="articleCardLabel typewriter">{article.type}</p>
          </div>
          <div className="articleCardTitleWrapper">
            <h2 className="articleCardTitle">{article.title}</h2>
          </div>
          <GatsbyImage
            placeholder="blurred"
            image={getImage(article.heroImage)}
          />
        </Link>
      </div>
    );
  });

  let articlesToShow = baseArticles;

  if (filter !== "All research") {
    let filteredArticles = articles.filter(
      (article) => article.type === filter
    );

    articlesToShow = filteredArticles.map(function (article) {
      return (
        <div className={`gridItem ${article.sizeOnHomepage}`}>
          <Link
            className={`articleCard  ${article.type}`}
            to={`/research/${article.slug}`}
          >
            <div className="articleCardLabelWrapper">
              <p className="articleCardLabel typewriter">{article.type}</p>
            </div>
            <div className="articleCardTitleWrapper">
              <h2 className="articleCardTitle">{article.title}</h2>
            </div>
            <GatsbyImage
              placeholder="blurred"
              image={getImage(article.heroImage)}
              alt={article.heroImage ? article.heroImage.title : ""}
            />
          </Link>
        </div>
      );
    });
  } else {
    articlesToShow = baseArticles;
  }

  return articlesToShow;
}
